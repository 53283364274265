<template>
  <div class="fail-payment-page-wrapper">
    <p class="error-text">Ошибка транзакции, обратитесь к администратору !</p>
  </div>
</template>

<script>
export default {
  name: "fail-payment",
};
</script>

<style lang="scss" scoped>
.fail-payment-page-wrapper {
  width: 100%;
  max-width: 1170px;
  margin: 76px auto;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .error-text {
    font-size: 20px;
    color: red;
  }
}
</style>